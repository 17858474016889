export const categoryStructure = {
  "krankheit": {
    "komplementaermedizinische_behandlung": [
      "osteopathie",
      "physiotherapie",
      "hydrotherapie",
      "lasertherapie",
      "akupunktur",
      "homoeopathische_pflanzliche_arzneimittel"
    ],
    "medizinalfutter": [
      "verschreibungspflichtiges_futter"
    ],
    "notfallbehandlung": [
      "behandlung_ausserhalb_der_sprechzeiten",
      "behandlung_waehrend_der_sprechzeiten",
      "hausbesuch_durch_tierarzt"
    ],
    "verhaltensmedizinische_behandlung": [
      "verhaltensstoerung"
    ],
    "zahnbehandlung": [
      "zahnerkrankung"
    ],
    "fortgeschrittene_behandlung": [
      "stammzellenbehandlung",
      "gentherapie",
      "transplantationschirurgie",
      "prothesen"
    ],
    "allgemeine_tieraerztliche_behandlung": [
      "none",
      "ohrentzuendung",
      "hauterkrankung",
      "schnupfen",
      "husten",
      "durchfall",
      "nierenerkrankung",
      "verstopfung",
      "blasenentzuendung",
      "urinabsatzbeschwerden",
      "fieber_unbekannter_ursache",
      "infektionskrankheit",
      "appetitlosigkeit",
      "magendarmentzuendung",
      "herzerkrankung",
      "lungenerkrankung",
      "rueckenschmerzen",
      "lahmheit",
      "augenerkrankung",
      "bauchspeicheldruesenentzuendung",
      "diabetes",
      "schilddruesenerkrankung",
      "analbeutelverstopfung",
      "laehmung",
      "hormonelle_erkrankung",
      "nervenerkrankung",
      "bandscheibenvorfall",
      "allergie",
      "chirurgie",
      "krebs"
    ]
  },
  "unfall": {
    "komplementaermedizinische_behandlung": [
      "osteopathie",
      "physiotherapie",
      "hydrotherapie",
      "lasertherapie",
      "akupunktur",
      "homoeopathische_pflanzliche_arzneimittel"
    ],
    "medizinalfutter": [
      "verschreibungspflichtiges_futter"
    ],
    "notfallbehandlung": [
      "behandlung_ausserhalb_der_sprechzeiten",
      "behandlung_waehrend_der_sprechzeiten",
      "hausbesuch_durch_tierarzt"
    ],
    "verhaltensmedizinische_behandlung": [
      "verhaltensstoerung"
    ],
    "zahnbehandlung": [
      "zahnunfall"
    ],
    "fortgeschrittene_behandlung": [
      "stammzellenbehandlung",
      "transplantationschirurgie",
      "prothesen"
    ],
    "allgemeine_tieraerztliche_behandlung": [
      "none",
      "abszess",
      "hautwunde",
      "autounfall",
      "vergiftung",
      "fremdkoerper",
      "knochenbruch",
      "krallenverletzung",
      "kreuzbandriss",
      "kippfenster",
      "sturz",
      "muskel_sehnenzerrung",
      "augenverletzung",
      "zahnverletzung",
      "bissverletzung",
      "blutung",
      "allergische_reaktion",
      "magendrehung",
      "verbrennung",
      "hitzschlag"
    ]
  },
  "notfallbetreuung": {
    "krankenhausaufenthalt_des_besitzers": [
      "notfallmaessiger_aufenthalt"
    ]
  },
  "praevention": {
    "praeventive_behandlungen": [
      "floh_und_wurmkuren",
      "impfungen",
      "jaehrliche_tieraerztliche_kontrolle",
      "kastration",
      "zahnsteinentfernung"
    ]
  }
}
