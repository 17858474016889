<main [class.no-editable]="!editable">
  <h1>{{ 'fnol-request.title' | translate }}</h1>
  <div class="part-container">
    <CPF-fnol-details-preview [fnolRequest]="fnolRequest" (searchContract)="findContractsByField($event)"
                              *ngIf="isStepAfter(FnolRequestStatusEnum.TODO)"></CPF-fnol-details-preview>
    <CPF-fnol-details [fnolDetail]="fnolRequest"
                      [isExpanded]="fnolRequest.processingStatus === FnolRequestStatusEnum.TODO && editable"></CPF-fnol-details>
    <div class="actions">
      <button mat-raised-button color="accent" *ngIf="isStepAfter(FnolRequestStatusEnum.TODO)" (click)="editFNOLDetails()">
        {{ 'fnol-request.button.edit-fnol-details' | translate }}
      </button>
      <button mat-raised-button color="primary" [disabled]="fnolRequest.processingStatus !== FnolRequestStatusEnum.TODO"
              (click)="confirmFNOLDetails()">
        {{ 'fnol-request.button.confirm-fnol-details' | translate }}
      </button>
    </div>
  </div>
  <div class="part-container">
    <CPF-invoice-parsed-preview [fnolRequest]="fnolRequest" [parsedInvoiceData]="fnolRequest.parsedInvoiceData"
                                [invoiceDocument]="fnolRequest.invoiceDocument"
                                (searchContract)="findContractsByField($event)"></CPF-invoice-parsed-preview>
  </div>
  <div class="part-container">
    <CPF-invoice-parsed-edit *ngIf="fnolRequest.processingStatus === FnolRequestStatusEnum.FNOL_DATA_CONFIRMED && editable"
                             [fnolRequest]="fnolRequest"></CPF-invoice-parsed-edit>
    <div class="actions">
      <button mat-raised-button color="accent" *ngIf="isStepAfter(FnolRequestStatusEnum.FNOL_DATA_CONFIRMED)" (click)="editInvoiceDetails()">
        {{ 'fnol-request.button.edit-invoice-details' | translate }}
      </button>
      <button mat-raised-button color="accent" *ngIf="fnolRequest.processingStatus == FnolRequestStatusEnum.FNOL_DATA_CONFIRMED" (click)="cancelInvoiceDetails()">
        {{ 'fnol-request.button.cancel-invoice-details' | translate }}
      </button>
      <button mat-raised-button color="primary"
              [disabled]="fnolRequest.processingStatus !== FnolRequestStatusEnum.FNOL_DATA_CONFIRMED || !validateInvoiceDetails()"
              (click)="confirmInvoiceDetails()">
        {{ 'fnol-request.button.confirm-invoice-details' | translate }}
      </button>
    </div>
  </div>
  <div class="part-container">
    <CPF-vat-and-discount-preview [fnolRequest]="fnolRequest"
                                  *ngIf="isStepAfter(FnolRequestStatusEnum.INVOICE_PARSED_CONFIRMED)"></CPF-vat-and-discount-preview>
    <CPF-vat-and-discount-details [fnolRequest]="fnolRequest"
                                  *ngIf="fnolRequest.processingStatus === FnolRequestStatusEnum.INVOICE_PARSED_CONFIRMED && fnolRequest.parsedInvoiceData && editable"></CPF-vat-and-discount-details>
    <div class="actions">
      <button mat-raised-button color="accent"
              *ngIf="isStepAfter(FnolRequestStatusEnum.INVOICE_PARSED_CONFIRMED)"
              (click)="editVATAndDiscount()">
        {{ 'fnol-request.button.edit-vat-and-discount' | translate }}
      </button>
      <button mat-raised-button
              [disabled]="fnolRequest.processingStatus !== FnolRequestStatusEnum.INVOICE_PARSED_CONFIRMED || !fnolRequest.parsedInvoiceData"
              (click)="confirmVATAndDiscount()" color="primary">
        {{ 'fnol-request.button.confirm-vat-and-discount' | translate }}
      </button>
    </div>
  </div>
  <div class="part-container">
    <CPF-contract-details-preview *ngIf="isStepAfter(FnolRequestStatusEnum.INVOICE_PARSED_CONFIRMED)"
                                  [contracts]="contractSelected"></CPF-contract-details-preview>
    <CPF-find-contract [searchValue]="searchContractValue"
                       [contractSelected]="contractSelected"
                       [isExpanded]="fnolRequest.processingStatus === FnolRequestStatusEnum.VAT_AND_DISCOUNT_SET && editable"
                       (contractsSelected)="contractsSelectedEvent($event)"
                       (contractDeselected)="contractDeselectedEvent($event)"
    ></CPF-find-contract>
    <div class="actions">
      <button mat-raised-button color="accent" *ngIf="isStepAfter(FnolRequestStatusEnum.VAT_AND_DISCOUNT_SET)"
              (click)="editContracts()">
        {{ 'fnol-request.button.edit-contracts' | translate }}
      </button>
      <button mat-raised-button
              [disabled]="fnolRequest.processingStatus !== FnolRequestStatusEnum.VAT_AND_DISCOUNT_SET || contractSelected.length === 0"
              (click)="confirmContracts()" color="primary">
        {{ 'fnol-request.button.confirm-contracts' | translate }}
      </button>
    </div>
  </div>
  <div class="part-container">
    <CPF-invoice-assign-items-preview [fnolRequest]="fnolRequest" [contractSelected]="contractSelected"
                                      *ngIf="isStepAfter(FnolRequestStatusEnum.CONTRACTS_ASSIGNED)"></CPF-invoice-assign-items-preview>
    <CPF-invoice-assign-items [contractSelected]="contractSelected" [parsedInvoiceData]="fnolRequest.parsedInvoiceData"
                              *ngIf="fnolRequest.processingStatus === FnolRequestStatusEnum.CONTRACTS_ASSIGNED && editable"
    ></CPF-invoice-assign-items>
    <div class="actions">
      <button mat-raised-button color="accent"
              *ngIf="isStepAfter(FnolRequestStatusEnum.CONTRACTS_ASSIGNED)"
              (click)="editAssignments()">
        {{ 'fnol-request.button.edit-assignments' | translate }}
      </button>
      <button mat-raised-button
              [disabled]="fnolRequest.processingStatus !== FnolRequestStatusEnum.CONTRACTS_ASSIGNED"
              (click)="confirmAssignments()" color="primary">
        {{ 'fnol-request.button.confirm-assignments' | translate }}
      </button>
    </div>
  </div>
  <div class="part-container">
    <CPF-invoice-items-coverage-check-preview [fnolRequest]="fnolRequest"
                                              *ngIf="isStepAfter(FnolRequestStatusEnum.INVOICE_ITEMS_TO_CONTRACT_ASSIGNED)"></CPF-invoice-items-coverage-check-preview>
    <CPF-invoice-items-coverage-check [fnolRequest]="fnolRequest"
                                      *ngIf="fnolRequest.processingStatus === FnolRequestStatusEnum.INVOICE_ITEMS_TO_CONTRACT_ASSIGNED && editable"
    ></CPF-invoice-items-coverage-check>
    <div class="actions">
      <button mat-raised-button color="accent"
              *ngIf="isStepAfter(FnolRequestStatusEnum.INVOICE_ITEMS_TO_CONTRACT_ASSIGNED)"
              (click)="editStatusCheck()">
        {{ 'fnol-request.button.edit-status' | translate }}
      </button>
      <button mat-raised-button
              [disabled]="(!invoiceItemsCoverageCheckComponent || !invoiceItemsCoverageCheckComponent.validateComponent()) || fnolRequest.processingStatus !== FnolRequestStatusEnum.INVOICE_ITEMS_TO_CONTRACT_ASSIGNED"
              (click)="confirmStatusCheck()" color="primary">
        {{ 'fnol-request.button.confirm-status' | translate }}
      </button>
    </div>
  </div>
  <div class="part-container">
    <CPF-classification-list [treatmentsValue]="fnolRequest.parsedInvoiceData" [contractSelected]="contractSelected"
                             *ngIf="fnolRequest.processingStatus === FnolRequestStatusEnum.TREATMENT_VALIDATED && editable"
    ></CPF-classification-list>
    <CPF-classification-list-preview [treatmentsValue]="fnolRequest.parsedInvoiceData.treatments" [contractSelected]="contractSelected"
                                     *ngIf="isStepAfter(FnolRequestStatusEnum.TREATMENT_VALIDATED)"></CPF-classification-list-preview>
    <div class="actions">
      <button mat-raised-button color="accent"
              *ngIf="isStepAfter(FnolRequestStatusEnum.TREATMENT_VALIDATED)"
              (click)="editClassificationAssignments()">
        {{ 'fnol-request.button.edit-classification-assignments' | translate }}
      </button>
      <button mat-raised-button
              [disabled]="fnolRequest.processingStatus !== FnolRequestStatusEnum.TREATMENT_VALIDATED || !validateClassificationAssignments()"
              (click)="confirmClassificationAssignments()" color="primary">
        {{ 'fnol-request.button.confirm-classification-assignments' | translate }}
      </button>
    </div>
  </div>
  <div class="part-container">
    <CPF-contract-used-up-franchise-preview [contractsWithUsedUpFranchise]="contractsUiUsedUpFranchise"
                                            *ngIf="isStepAfter(FnolRequestStatusEnum.CLASSIFICATIONS_ASSIGNED)"></CPF-contract-used-up-franchise-preview>
    <CPF-contract-used-up-franchise [contractsWithUsedUpFranchise]="contractsUiUsedUpFranchise" [treatmentsWithClassification]="fnolRequest.parsedInvoiceData.treatments"
                                    *ngIf="fnolRequest.processingStatus === FnolRequestStatusEnum.CLASSIFICATIONS_ASSIGNED && editable"></CPF-contract-used-up-franchise>
    <div class="actions">
      <button mat-raised-button color="accent"
              *ngIf="isStepAfter(FnolRequestStatusEnum.CLASSIFICATIONS_ASSIGNED)"
              (click)="editUsedUpFranchise()">
        {{ 'fnol-request.button.edit-used-up-franchise' | translate }}
      </button>
      <button mat-raised-button
              [disabled]="fnolRequest.processingStatus !== FnolRequestStatusEnum.CLASSIFICATIONS_ASSIGNED"
              (click)="confirmUsedUpFranchise()" color="primary">
        {{ 'fnol-request.button.confirm-used-up-franchise' | translate }}
      </button>
    </div>
  </div>
  <button mat-raised-button color="primary" *ngIf="fnolRequest.processingStatus === FnolRequestStatusEnum.USED_UP_FRANCHISE_FILLED && editable"
          (click)="sendFnolRequest()">
    {{ 'fnol-request.button.send-for-calculation' | translate }}
  </button>
  <div class="part-container">
    <CPF-mfv-claim-calculation [calculatedMfvClaims]="calculatedMfvClaims" (editClassification)="editClassificationAssignments()"
                               *ngIf="isStepAfter(FnolRequestStatusEnum.USED_UP_FRANCHISE_FILLED)"></CPF-mfv-claim-calculation>
  </div>
  <div class="part-container">
    <CPF-mfv-claim-detail [calculatedMfvClaims]="calculatedMfvClaims" [fnolRequest]="fnolRequest" (completeRequest)="completeFNOLRequest()"
                          *ngIf="isStepAfter(FnolRequestStatusEnum.USED_UP_FRANCHISE_FILLED)"></CPF-mfv-claim-detail>
  </div>
</main>
