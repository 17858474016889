import { Component, Input } from '@angular/core';
import { Contract, ContractPreview, Treatment, TreatmentUI } from "../../../models";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'CPF-classification-list-preview',
  templateUrl: './classification-list-preview.component.html',
  styleUrls: ['./classification-list-preview.component.scss']
})
export class ClassificationListPreviewComponent {
  treatments: TreatmentUI[] = [];
  contracts: Contract[] = [];

  constructor(private translate: TranslateService) {
  }

  @Input() set treatmentsValue(treatments: Treatment[]) {
    this.treatments = treatments.map(t => (
        {
          ...t,
          expanded: false,
          positions: t.positions.map(p => (
            {
              ...p,
              copySource: false,
              copyTarget: false,
              classification: p.classification || {}
            }
          ))
        }
      )
    );
  }

  @Input() set contractSelected(contractSelected: ContractPreview[]) {
    this.contracts = contractSelected.map(c => c.contracts).flat();
  }

  translateReasonNotCovered(reason?: string) {
    if (!reason) {
      return 'Unknown'
    }
    return this.translate.instant(`classification-types.reasons-not-covered.${reason}`);
  }

  toggleExpandTreatment(treatment: TreatmentUI) {
    treatment.expanded = !treatment.expanded;
  }
}
