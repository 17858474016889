<main>
  <div class="title-2">{{ 'classification-list.title' | translate }}</div>
  <div class="container" *ngFor="let treatment of treatments">
    <ng-container *ngIf="treatment.contract as selectedContract">
      <div class="top-row">
        <div class="top-row-info info">
          <div>
            <label>{{ 'classification-list.labels.pet-name' | translate }}</label>
            <p>{{ treatment.petName }}</p>
          </div>
          <div>
            <label>{{ 'classification-list.labels.date' | translate }}</label>
            <p>{{ treatment.date | date:'dd.MM.YYYY' }}</p>
          </div>
          <div>
            <label>{{ 'classification-list.labels.contract' | translate }}</label>
            <span class="pointer" (click)="openContractDetailsSidebar(treatment.contract)">
              {{ selectedContract.full_contract_number }}
            </span>
          </div>
        </div>
        <div class="actions">
          <mat-icon (click)="toggleExpandTreatment(treatment)" class="expand-button" [class.expand]="!treatment.expanded">expand_less</mat-icon>
        </div>
      </div>
      <div class="treatment-list" *ngIf="treatment.expanded">
        <div class="treatment" *ngFor="let position of treatment.positions">
          <div class="info info-treatment">
            <div>
              <label>{{ 'classification-list.labels.position-name' | translate }}</label>
              <p>{{ position.name }}</p>
            </div>
            <div>
              <label>{{ 'classification-list.labels.quantity' | translate }}</label>
              <p>{{ position.quantity }}</p>
            </div>
            <div>
              <label>{{ 'classification-list.labels.vat-percentage' | translate }}</label>
              <p>{{ position.vatPercentage }}</p>
            </div>
            <div>
              <label>{{ 'classification-list.labels.amount' | translate }}</label>
              <p>{{ position.amount }}</p>
            </div>

            <div *ngIf="position.classification" class="covered-or-not">
              <section class="treatment-form-section">
                <mat-label>{{ 'classification-list.labels.covered' | translate }}</mat-label>
                <mat-checkbox [(ngModel)]="position.classification.covered"></mat-checkbox>
              </section>
            </div>

            <div *ngIf="position.classification" class="covered-or-not">
              <section class="treatment-form-section">
                <mat-label>{{ 'classification-list.labels.ignore-waiting-period' | translate }}</mat-label>
                <mat-checkbox [(ngModel)]="position.classification.ignoreWaitingPeriod"></mat-checkbox>
              </section>
            </div>

            <div class="reason-not-covered" *ngIf="position.classification">
              <mat-form-field [class.treatment-item-hidden]="position.classification.covered">
                <mat-label>{{ 'classification-list.labels.reason-not-covered' | translate }}</mat-label>
                <mat-select [(ngModel)]="position.classification.reasonNotCovered">
                  <mat-option *ngFor="let reasonNotCovered of classificationReasonNotCovered"
                              [value]="reasonNotCovered.key">{{ reasonNotCovered.value }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="treatment-action-check" *ngIf="isSelectedModeActive(treatment) && !position.copySource">
              <mat-checkbox [(ngModel)]="position.copyTarget"></mat-checkbox>
            </div>
          </div>

          <div class="treatment-form-container" *ngIf="position.classification && (!isSelectedModeActive(treatment)  || position.copySource)">
            <div class="form-part">
              <mat-form-field>
                <mat-label>{{ 'classification-list.labels.cluster' | translate }}</mat-label>
                <mat-select [(ngModel)]="position.classification.clusterAssignment">
                  <mat-option *ngFor="let number of ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50']"
                              [value]="number">{{ number }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field>
                <mat-label>{{ 'classification-list.labels.type' | translate }}</mat-label>
                <mat-select panelWidth="" [(ngModel)]="position.classification.type" (selectionChange)="typeChanged(position)">
                  <mat-option *ngFor="let type of classificationType" [value]="type">{{ type }}</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field>
                <mat-label>{{ 'classification-list.labels.subtype' | translate }}</mat-label>
                <mat-select panelWidth="" [(ngModel)]="position.classification.subtype" (selectionChange)="subtypeChanged(position)"  [disabled]="!position.classification.type">
                  <mat-option *ngFor="let subtype of subtypesToShowForPosition(position)" [value]="subtype">{{ subtype }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field>
                <mat-label>{{ 'classification-list.labels.cause' | translate }}</mat-label>
                <mat-select panelWidth="" [(ngModel)]="position.classification.cause" [disabled]="!position.classification.subtype">
                  <mat-option *ngFor="let cause of causeToShowForPosition(position)" [value]="cause">{{ cause }}</mat-option>
                </mat-select>
              </mat-form-field>

              <section class="treatment-form-section">
                <mat-label>{{ 'classification-list.labels.preexisting-condition' | translate }}</mat-label>
                <mat-checkbox style="transform: scale(0.75);"
                              [(ngModel)]="position.classification.preexistingCondition"></mat-checkbox>
              </section>

              <section class="treatment-form-section">
                <mat-label>{{ 'classification-list.labels.emergency-abroad' | translate }}</mat-label>
                <mat-checkbox [(ngModel)]="position.classification.emergencyAbroad"></mat-checkbox>
              </section>

              <section class="treatment-form-section">
                <mat-label>{{ 'classification-list.labels.chronic-disease' | translate }}</mat-label>
                <mat-checkbox [(ngModel)]="position.classification.chronicDisease"></mat-checkbox>
              </section>

              <section class="treatment-form-section">
                <mat-label>{{ 'classification-list.labels.follow-up-treatment' | translate }}</mat-label>
                <mat-checkbox [(ngModel)]="position.classification.followUpTreatment" (change)="onFollowUpCheckboxChange($event, position)"></mat-checkbox>
              </section>

              <mat-form-field [class.treatment-item-hidden]="!position.classification.followUpTreatment">
                <mat-label>{{ 'classification-list.labels.follow-up-cluster-reference' | translate }}</mat-label>
                <mat-select [(ngModel)]="position.classification.followUpTreatmentCluster">
                  <mat-option *ngFor="let number of ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50']"
                              [value]="number">{{ number }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <section class="follow-up-treatment-section"
                       [class.treatment-item-hidden]="!position.classification.followUpTreatment">
                <mat-label>{{ 'classification-list.labels.follow-up-claim-reference' | translate }}</mat-label>
                <div class="follow-up-claims-value">
                  <span>{{ position.classification.followUpTreatmentClaim }}</span>
                  <button mat-button (click)="openClaimsForContract(treatment.contract, position.classification)">
                    {{ 'classification-list.buttons.select' | translate }}
                  </button>
                </div>
              </section>

              <div *ngIf="position.classification && askForAccidentDate(position)" id="classification-list-accident-date-div">
                <mat-label>{{ 'classification-list.labels.date-of-accident'|translate }}</mat-label>

                <mat-form-field>
                  <input
                    matInput
                    [(ngModel)]="position.classification.accidentDate"
                    [matDatepicker]="accidentDatePicker"
                    (ngModelChange)="onAccidentDateChange($event, position)">
                    <mat-datepicker-toggle matIconSuffix [for]="accidentDatePicker"></mat-datepicker-toggle>
                  <mat-datepicker #accidentDatePicker></mat-datepicker>
                </mat-form-field>
              </div>

              <div class="treatment-actions">
                <button (click)="applyToOthers(position)" *ngIf="isApplyToOthersEnabled(treatment)" color="primary"
                        mat-raised-button>{{ 'classification-list.buttons.apply-to-others' | translate }}
                </button>
                <button (click)="selectAll(treatment)" *ngIf="position.copySource"
                        mat-raised-button>{{ 'classification-list.buttons.select-all' | translate }}
                </button>
                <button (click)="applySelection(treatment)" *ngIf="position.copySource" [disabled]="!isSelectedAtLeastOne(treatment)" color="primary"
                        mat-raised-button>{{ 'classification-list.buttons.apply-selection' | translate }}
                </button>
                <button (click)="cancelSelection(treatment)" *ngIf="position.copySource" color="warn"
                        mat-raised-button>{{ 'classification-list.buttons.cancel-selection' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</main>
